import React from "react";
// @ts-ignore
import { Item, Span } from "react-html-email";
import Box from "react-html-email/lib/components/Box";
import { EmailLink } from "./EmailLink";

const footerContainer = {
  color: "white",
  paddingBottom: "13px",
  paddingTop: "13px",
  textDecoration: "none",
  fontSize: "12px",
  width: "100%",
};

const footerContent = {
  fontSize: "10px",
  color: "rgb(117, 128, 140)",
};

const footerLink = {
  color: "#04f73d",
  fontSize: "10px",
};

const EmailFooter = () => {
  return (
    <Box style={footerContainer} align="center">
      <Item style={{ height: 20 }} />
      <Item align="center">
        <Span style={footerContent}>
          WEALTHX | ABN 27 608 729 942 AUSTRALIAN CREDIT LICENSE 48720
        </Span>
      </Item>
      <Item align="center">
        <Span style={footerContent}>
          <EmailLink style={footerLink} href="https://support.ovrsea.com/hc/fr">
            UNSUBSCRIBE
          </EmailLink>
          <Span style={footerContent}> | </Span>
          <EmailLink
            style={footerLink}
            href="https://wealthx.au/privacy-policy/"
          >
            DATA & PRIVACY POLICY
          </EmailLink>
        </Span>
      </Item>
    </Box>
  );
};

export { EmailFooter };
