import React from "react";
import Box from "react-html-email/lib/components/Box";
import Item from "react-html-email/lib/components/Item";
import Span from "react-html-email/lib/components/Span";

const CHART_HEIGHT = 150;

const MonthlySpendingChart = () => {
  return (
    <Box width="100%" style={{ width: "100% !important" }}>
      <Item width="100%">
        <Box width="100%" style={{ width: "100% !important" }}>
          <tr>
            <td style={{ width: "50%", fontWeight: "bold", fontSize: 18 }}>
              This Months Spending
            </td>
            <td style={{ width: "50%", textAlign: "right" }}>
              <Span>{`{{currentCashBalance}}`} Current Cash Balance</Span>
            </td>
          </tr>
        </Box>
      </Item>
      <Item style={{ height: 30 }} />
      <Item width="100%">
        <Box
          width="100%"
          style={{
            height: CHART_HEIGHT,
            width: "100% !important",
            borderCollapse: "collapse",
            tableLayout: "fixed",
          }}
        >
          <tr>
            {`{{#each monthlySpending}}`}
            {`{{#if @last}}`}
            <td
              valign="bottom"
              style={{
                width: "2%",
                borderRight: "none",
                fontWeight: 300,
                textAlign: "left",
                fontSize: 0,
                margin: 0,
                padding: 0,
                height: CHART_HEIGHT,
              }}
            >
              <Box width="100%" style={{ width: "100% !important" }}>
                <Item
                  style={{
                    height: `{{outRangeHeight}}px`,
                  }}
                >
                  &nbsp;
                </Item>
                <Item
                  style={{
                    height: `{{cashBalanceHeight}}px`,
                    backgroundColor: "#E9EDF2",
                  }}
                >
                  &nbsp;
                </Item>
                <Item
                  style={{
                    height: `{{incomingHeight}}px`,
                    backgroundColor: "#04f73d",
                  }}
                >
                  &nbsp;
                </Item>
              </Box>
            </td>
            {`{{else}}`}
            <td
              valign="bottom"
              style={{
                width: "2%",
                borderRight: "6px solid white",
                fontWeight: 300,
                textAlign: "left",
                fontSize: 0,
                margin: 0,
                padding: 0,
                height: CHART_HEIGHT,
              }}
            >
              <Box width="100%" style={{ width: "100% !important" }}>
                <Item
                  style={{
                    height: `{{outRangeHeight}}px`,
                  }}
                >
                  &nbsp;
                </Item>
                <Item
                  style={{
                    height: `{{cashBalanceHeight}}px`,
                    backgroundColor: "#E9EDF2",
                  }}
                >
                  &nbsp;
                </Item>
                <Item
                  style={{
                    height: `{{incomingHeight}}px`,
                    backgroundColor: "#04f73d",
                  }}
                >
                  &nbsp;
                </Item>
              </Box>
            </td>
            {`{{/if}}`}
            {`{{/each}}`}
          </tr>
        </Box>
      </Item>
      <Item style={{ height: 10 }} />
      <Item>
        <Box
          width="100%"
          style={{
            width: "100% !important",
          }}
        >
          <tr>
            <td
              style={{
                width: 9,
                fontWeight: 300,
                textAlign: "left",
                fontSize: 0,
                margin: 0,
                padding: 0,
              }}
            >
              &nbsp;
            </td>
            <td
              style={{
                height: "1px",
                backgroundColor: "#E8E8EB",
              }}
            />
            <td
              style={{
                width: 9,
                fontWeight: 300,
                textAlign: "left",
                fontSize: 0,
                margin: 0,
                padding: 0,
              }}
            >
              &nbsp;
            </td>
          </tr>
        </Box>
      </Item>
      <Item style={{ height: 10 }} />
      <Item width="100%">
        <Box
          width="100%"
          style={{
            width: "100% !important",
            borderCollapse: "collapse",
            tableLayout: "fixed",
          }}
        >
          <tr>
            {`{{#each monthlySpendingDateRange}}`}
            {`{{#if @last}}`}
            <td
              valign="bottom"
              style={{
                fontWeight: 300,
                textAlign: "left",
                fontSize: 14,
              }}
            >
              {`{{this}}`}
            </td>
            {`{{else}}`}
            <td
              valign="bottom"
              style={{
                width: "23.5%",
                borderRight: "6px solid white",
                fontWeight: 300,
                textAlign: "left",
                fontSize: 14,
              }}
            >
              {`{{this}}`}
            </td>
            {`{{/if}}`}

            {`{{/each}}`}
          </tr>
        </Box>
      </Item>
      <Item style={{ height: 15 }} />
      <Item width="100%">
        <Box
          width="100%"
          style={{
            textAlign: "center",
            width: "100% !important",
          }}
        >
          <tr style={{ display: "inline" }}>
            <td style={{ marginRight: 20 }}>
              <Box width="100%">
                <tr>
                  <td>
                    <Span>Cash Balance</Span>
                  </td>
                  <td style={{ width: 16 }}>&nbsp;</td>
                  <td
                    style={{
                      marginLeft: 5,
                      height: 13,
                      width: 20,
                      backgroundColor: "#E9EDF2",
                    }}
                  />
                </tr>
              </Box>
            </td>
            <td style={{ width: 32 }}>&nbsp;</td>
            <td style={{ marginLeft: 32 }}>
              <Box width="100%">
                <tr>
                  <td>
                    <Span>Incomings</Span>
                  </td>
                  <td style={{ width: 16 }}>&nbsp;</td>
                  <td
                    style={{
                      marginLeft: 5,
                      height: 13,
                      width: 20,
                      backgroundColor: "#04f73d",
                    }}
                  />
                </tr>
              </Box>
            </td>
          </tr>
        </Box>
      </Item>
    </Box>
  );
};

export default MonthlySpendingChart;
