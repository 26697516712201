import React from "react";
import Box from "react-html-email/lib/components/Box";
import Item from "react-html-email/lib/components/Item";
import Span from "react-html-email/lib/components/Span";

const COLUMN_WIDTH = `${100 / 12}%`;
const CHART_HEIGHT = 150;

const BuyingPowerChart = () => {
  return (
    <Box width="100%" style={{ width: "100% !important" }}>
      <Item>
        <Box width="100%" style={{ width: "100% !important" }}>
          <tr>
            <td style={{ width: "50%", fontWeight: "bold", fontSize: 18 }}>
              Buying Power Chart
            </td>
            <td style={{ width: "50%", textAlign: "right" }}>
              <Span>{`{{currentBuyingPower}}`} Current Buying Power</Span>
            </td>
          </tr>
        </Box>
      </Item>

      <Item style={{ height: 30 }} />
      <Item>
        <Box
          width="100%"
          style={{
            height: CHART_HEIGHT,
            width: "100% !important",
          }}
        >
          <tr>
            {`{{#each buyingPowerChart}}`}
            <td
              style={{
                width: COLUMN_WIDTH,
                borderRight: "15px solid white",
                borderLeft: "15px solid white",
              }}
            >
              <Box width="100%" style={{ width: "100% !important" }}>
                <Item
                  style={{
                    maxWidth: COLUMN_WIDTH,
                    width: COLUMN_WIDTH,
                    height: `{{outRangeHeight}}px`,
                  }}
                />
                <Item
                  style={{
                    height: `{{buyingPowerHeight}}px`,
                    backgroundColor: "#04f73d",
                  }}
                />
              </Box>
            </td>
            {`{{/each}}`}
          </tr>
        </Box>
      </Item>
      <Item style={{ height: 5 }} />
      <Item>
        <Box
          width="100%"
          style={{
            width: "100% !important",
          }}
        >
          <tr>
            <td
              style={{
                width: 25,
                fontWeight: 300,
                textAlign: "left",
                fontSize: 0,
                margin: 0,
                padding: 0,
              }}
            >
              &nbsp;
            </td>
            <td
              style={{
                height: "1px",
                backgroundColor: "#E8E8EB",
              }}
            />
            <td
              style={{
                width: 25,
                fontWeight: 300,
                textAlign: "left",
                fontSize: 0,
                margin: 0,
                padding: 0,
              }}
            >
              &nbsp;
            </td>
          </tr>
        </Box>
      </Item>
      <Item style={{ height: 15 }} />
      <Item>
        <Box
          width="100%"
          style={{
            width: "100% !important",
          }}
        >
          <tr>
            {`{{#each buyingPowerChart}}`}
            <td
              style={{
                width: COLUMN_WIDTH,
                borderRight: "15px solid white",
                borderLeft: "15px solid white",
              }}
            >
              {`{{date}}`}
            </td>
            {`{{/each}}`}
          </tr>
        </Box>
      </Item>
    </Box>
  );
};

export default BuyingPowerChart;
