import React from "react";
import Box from "react-html-email/lib/components/Box";
import Item from "react-html-email/lib/components/Item";
import Span from "react-html-email/lib/components/Span";

const OtherExpense = () => {
  return (
    <Item>
      {`{{#if otherExpenseCategories.outRangePercent}}`}
      {`{{#with otherExpenseCategories}}`}
      <Box width="100%" style={{ width: "100% !important" }}>
        <Item>
          <Box width="100%">
            <Item>
              <Span>
                <Span
                  style={{ fontWeight: "bold" }}
                >{`{{value}} | {{percent}}`}</Span>{" "}
                {`{{category}}`}
              </Span>
            </Item>
            <Item style={{ height: 10 }} />
          </Box>
        </Item>

        <Item>
          <Box width="100%" style={{ width: "100% !important" }}>
            <tr style={{ height: 22 }}>
              {`{{#each otherExpenseLists}}`}

              <td
                style={{
                  width: `{{percent}}%`,
                  backgroundColor: "{{color}}",
                }}
              />

              {`{{/each}}`}
              <td
                style={{
                  width: `{{outRangePercent}}%`,
                  backgroundColor: "#E9EDF2",
                }}
              />
            </tr>
          </Box>
        </Item>
        <Item style={{ height: 30 }} />
      </Box>
      {`{{/with}}`}
      {`{{/if}}`}
    </Item>
  );
};

export default OtherExpense;
