import React from "react";
import Box from "react-html-email/lib/components/Box";
import Image from "react-html-email/lib/components/Image";
import Item from "react-html-email/lib/components/Item";
import Span from "react-html-email/lib/components/Span";

const Top5ExpenseChart = () => {
  return (
    <>
      {`{{#each top5ExpenseCategories}}`}
      <Item>
        <Box width="100%" style={{ width: "100% !important" }}>
          <Item>
            <Box width="100%">
              <Item>
                <Span>
                  <Span
                    style={{ fontWeight: "bold" }}
                  >{`{{value}} | {{percent}}`}</Span>{" "}
                  {`{{category}}`}
                </Span>
              </Item>
              <Item style={{ height: 10 }} />
            </Box>
          </Item>

          <Item>
            <Box width="100%" style={{ width: "100% !important" }}>
              <tr style={{ height: 22, backgroundColor: "#E9EDF2" }}>
                <td
                  style={{
                    display: "block",
                    width: `{{percent}}`,
                    backgroundColor: "{{color}}",
                  }}
                >
                  <Image
                    height="auto"
                    src="{{icon}}"
                    width={20}
                    style={{ padding: 3 }}
                  />
                </td>
              </tr>
            </Box>
          </Item>
          <Item style={{ height: 30 }} />
        </Box>
      </Item>
      {`{{/each}}`}
    </>
  );
};

export default Top5ExpenseChart;
