import React from "react";
import { Span } from "react-html-email";
import Box from "react-html-email/lib/components/Box";
import Item from "react-html-email/lib/components/Item";

const IncomingOutgoingChart = () => {
  return (
    <Box
      style={{
        fontWeight: 300,
        width: "100% !important",
        borderCollapse: "collapse",
      }}
    >
      <Item align="center" style={{ width: "100%" }}>
        <Span>{`{{ status }}`} </Span>
      </Item>
      <Item style={{ height: 30 }} />
      <Item
        style={{
          borderBottom: "1px solid #E8E8EB",
          width: "100%",
        }}
      >
        <Box
          style={{
            width: "100% !important",
            borderCollapse: "collapse",
          }}
        >
          <tr>
            <td style={{ width: "50%" }}>
              <Box
                style={{
                  width: "100% !important",
                  borderCollapse: "collapse",
                }}
                align="center"
              >
                <tr>
                  <td
                    style={{
                      width: "33%",
                      fontWeight: 300,
                      textAlign: "left",
                      fontSize: 0,
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    &nbsp;
                  </td>
                  <td
                    style={{
                      width: "34%",
                      fontWeight: 300,
                      textAlign: "left",
                      fontSize: 0,
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <Box
                      width="100%"
                      style={{
                        width: "100% !important",
                        borderCollapse: "collapse",
                      }}
                    >
                      <Item
                        style={{
                          backgroundColor: "#fff",
                          height: "{{incoming.outRangeHeight}}px",
                        }}
                      />
                      <Item
                        style={{
                          borderRadius: "5px 5px 0 0",
                          backgroundColor: "#04f73d",
                          height: "{{incoming.incomingHeight}}px",
                        }}
                      >
                        &nbsp;
                      </Item>
                    </Box>
                  </td>
                  <td
                    style={{
                      width: "33%",
                      fontWeight: 300,
                      textAlign: "left",
                      fontSize: 0,
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    &nbsp;
                  </td>
                </tr>
              </Box>
            </td>
            <td style={{ width: "50%" }}>
              <Box
                style={{
                  width: "100% !important",
                  borderCollapse: "collapse",
                }}
                align="center"
              >
                <tr>
                  <td style={{ width: "33%" }}>&nbsp;</td>
                  <td
                    style={{
                      width: "34%",
                      fontWeight: 300,
                      textAlign: "left",
                      fontSize: 0,
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <Box
                      style={{
                        width: "100% !important",
                        borderCollapse: "collapse",
                      }}
                    >
                      <Item
                        style={{
                          backgroundColor: "#fff",
                          height: "{{expense.outRangeHeight}}px",
                        }}
                      />
                      <Item
                        style={{
                          borderRadius: "5px 5px 0 0",
                          backgroundColor: "#17202A",
                          height: "{{expense.expenseHeight}}px",
                        }}
                      >
                        &nbsp;
                      </Item>
                    </Box>
                  </td>
                  <td style={{ width: "33%" }}>&nbsp;</td>
                </tr>
              </Box>
            </td>
          </tr>
        </Box>
      </Item>
      <Item style={{ height: 20 }} />
    </Box>
  );
};

export default IncomingOutgoingChart;
