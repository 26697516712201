import React from "react";
import { Box, Email, Item } from "react-html-email";
import BuyingPowerChart from "./BuyingPowerChart";
import { EmailFooter } from "./EmailFooter";
import EmailHeader from "./EmailHeader";
import WeeklyReportEmailHeader from "./WeeklyReportEmailHeader";
import FinancialChart from "./FinancialChart";
import LoanOptions from "./LoanOptions";
import MonthlySpendingChart from "./MonthlySpendingChart";
import PropertySales from "./PropertySales";
import SharingReferral from "./SharingReferral";
import TalkToBroker from "./TalkToBroker";

const backgroundStyle = {
  backgroundColor: "#FFF",
  borderRadius: 7,
  margin: "0 auto",
  width: "100% !important",
  minWidth: "100% !important",
  padding: "48px 32px",
  borderCollapse: "separate",
  webkitTextSizeAdjust: "none",
  textSizeAdjust: "none",
};

const containerStyle = {
  backgroundColor: "#F5F8FA",
  width: "100%",
};

const fluidItemStyle = {
  height: 48,
  textAlign: "center",
  textDecoration: "none",
};

export const MonthlyReport = ({ subject }) => (
  <Box align="center" style={containerStyle}>
    <Item>
      <Email align="center" title={subject} width={664}>
        <Item align="center">
          <Box bgcolor="white" class="email-container" style={backgroundStyle}>
            <Item>
              <EmailHeader />
            </Item>
            <Item style={{ height: 50 }} />
            {`{{#if monthlySpending}}`}
            <Item>
              <MonthlySpendingChart />
            </Item>
            <Item style={{ height: 150 }} />
            {`{{/if}}`}
            <FinancialChart />
            <Item style={{ height: 150 }} />
            {`{{#if buyingPowerChart}}`}
            <Item>
              <BuyingPowerChart />
            </Item>
            <Item style={{ height: 50 }} />
            {`{{/if}}`}
            {`{{#if loanOptions}}`}
            <Item>
              <LoanOptions />
            </Item>
            <Item style={{ height: 40 }} />
            {`{{/if}}`}
            <Item style={fluidItemStyle}>
              <TalkToBroker />
            </Item>
            <Item style={{ height: 150 }} />
            {`{{#if propertyGroup.groupOne}}`}
            <Item>
              <PropertySales />
            </Item>
            <Item style={{ height: 150 }} />
            {`{{/if}}`}
            <Item>
              <SharingReferral />
            </Item>
            <Item style={{ height: 150 }} />
            <Item>
              <EmailFooter />
            </Item>
          </Box>
        </Item>
      </Email>
    </Item>
  </Box>
);

export const WeeklyReport = ({ subject }) => (
  <>
    <Box align="center" style={containerStyle}>
      <Item>
        <Email
          align="center"
          title={subject}
          width={664}
          style={{ minWidth: "664px !important" }}
        >
          <Item align="center">
            <Box
              bgcolor="white"
              class="email-container"
              style={backgroundStyle}
            >
              <Item>
                <WeeklyReportEmailHeader />
              </Item>
              <Item style={{ height: 150 }} />
              <FinancialChart />
              <Item style={{ height: 150 }} />
              <Item>
                <SharingReferral />
              </Item>
              <Item style={{ height: 150 }} />
              <Item>
                <EmailFooter />
              </Item>
            </Box>
          </Item>
        </Email>
      </Item>
    </Box>
  </>
);
