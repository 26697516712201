import React from "react";
// @ts-ignore
import { A } from "react-html-email";

const linkStyle = {
  color: "rgb(117, 128, 140)",
  fontSize: 15,
};

const EmailLink = ({ children, href, style }) => (
  <A href={href} style={{ ...linkStyle, ...style }}>
    {children}
  </A>
);

export { EmailLink };
