import React from "react";
import Box from "react-html-email/lib/components/Box";
import Item from "react-html-email/lib/components/Item";
import Span from "react-html-email/lib/components/Span";
import ExpensesCategoriesChart from "./ExpensesCategoriesChart";
import IncomingOutgoingChart from "./IncomingOutgoingChart";

const FinancialChart = () => {
  return (
    <>
      <Item>
        <Box width="100%" style={{ width: "100% !important" }}>
          <tr>
            {`{{#if top5ExpenseCategories}}`}
            <td
              style={{
                width: "40%",
                verticalAlign: "top",
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              Expense Categories
            </td>
            <td style={{ width: "10%" }}>&nbsp;</td>
            {`{{/if}}`}
            {`{{#if incomeAndExpense.status}}`}
            {`{{#with incomeAndExpense}}`}
            <td
              style={{
                width: "50%",
                verticalAlign: "top",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: 18,
              }}
            >
              Incomings vs Outgoings
            </td>
            {`{{/with}}`}
            {`{{/if}}`}
          </tr>
        </Box>
      </Item>
      <Item style={{ height: 30 }} />
      <Item>
        <Box width="100%" style={{ width: "100% !important" }}>
          <tr>
            {`{{#if top5ExpenseCategories}}`}
            <td
              style={{
                width: "40%",
                verticalAlign: "bottom",
              }}
            >
              <ExpensesCategoriesChart />
            </td>
            <td style={{ width: "10%" }}>&nbsp;</td>
            {`{{/if}}`}
            {`{{#if incomeAndExpense.status}}`}
            {`{{#with incomeAndExpense}}`}
            <td
              style={{
                width: "50%",
                verticalAlign: "bottom",
              }}
            >
              <IncomingOutgoingChart />
            </td>
            {`{{/with}}`}
            {`{{/if}}`}
          </tr>
        </Box>
      </Item>
      <Item>
        <Box width="100%" style={{ width: "100% !important" }}>
          <tr>
            {`{{#if top5ExpenseCategories}}`}
            <td
              style={{
                width: "40%",
                verticalAlign: "top",
              }}
            >
              &nbsp;
            </td>
            <td style={{ width: "10%" }}>&nbsp;</td>
            {`{{/if}}`}
            {`{{#if incomeAndExpense.status}}`}
            {`{{#with incomeAndExpense}}`}
            <td
              style={{
                width: "50%",
                verticalAlign: "top",
              }}
            >
              <Box width="100%" style={{ width: "100% !important" }}>
                <Item>
                  <Box
                    width="100%"
                    style={{
                      width: "100% !important",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tr>
                      <td style={{ width: "50%" }}>
                        <Box
                          style={{
                            width: "100% !important",
                            borderCollapse: "collapse",
                          }}
                        >
                          <Item align="center">
                            <Span>Incomings</Span>
                          </Item>
                          <Item style={{ height: 10 }} />
                          <Item align="center">
                            <Span>{`{{incoming.totalIncoming}}`}</Span>
                          </Item>
                        </Box>
                      </td>
                      <td style={{ width: "50%" }}>
                        <Box
                          style={{
                            width: "100% !important",
                            borderCollapse: "collapse",
                          }}
                        >
                          <Item align="center">
                            <Span>Outgoings</Span>
                          </Item>
                          <Item style={{ height: 10 }} />
                          <Item align="center">
                            <Span>{`{{expense.totalExpense}}`}</Span>
                          </Item>
                        </Box>
                      </td>
                    </tr>
                  </Box>
                </Item>
              </Box>
            </td>
            {`{{/with}}`}
            {`{{/if}}`}
          </tr>
        </Box>
      </Item>
    </>
  );
};

export default FinancialChart;
