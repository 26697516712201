import React from "react";
// @ts-ignore
import { Item } from "react-html-email";
import Box from "react-html-email/lib/components/Box";
import Image from "react-html-email/lib/components/Image";

const EmailHeader = () => {
  return (
    <Box width="100%" style={{ width: "100% !important" }}>
      <tr>
        <td style={{ width: "40%" }}>
          <Image
            height="auto"
            src="https://d1rndq5v7rs3ry.cloudfront.net/logo.png"
            width={160}
          />
        </td>
        <td style={{ width: "60%" }}>
          <Box width="100%">
            <Item align="right" style={{ fontWeight: "bold", fontSize: 18 }}>
              {`Monthly Update for {{name}}`}
            </Item>
            <Item align="right" style={{ fontSize: 14 }}>
              {`{{from}} - {{to}}`}
            </Item>
          </Box>
        </td>
      </tr>
    </Box>
  );
};

export default EmailHeader;
