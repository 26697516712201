import React from "react";
import Box from "react-html-email/lib/components/Box";
import Image from "react-html-email/lib/components/Image";
import Item from "react-html-email/lib/components/Item";
import Span from "react-html-email/lib/components/Span";

const loanOptionsItemStyle = {
  textAlign: "center",
  width: "33%",
};

const loanOptionsTitle = {
  textAlign: "center",
  fontWeight: "bold",
  fontSize: 18,
};

const loanOptionsValue = {
  fontWeight: "bold",
  verticalAlign: "middle",
  fontSize: 16,
};

const LoanOption = ({ image, loanValue }) => {
  return (
    <Box width="100%" style={{ width: "100% !important" }}>
      <td style={{ width: "8%" }} />
      <td>
        <Box
          width="100%"
          style={{ width: "100% !important", textAlign: "center" }}
          align="center"
        >
          <Item align="center">
            <Image src={image} width={100} />
          </Item>
          <Item style={{ height: 10 }} />
          <Item>
            <Span style={loanOptionsValue}>{loanValue}</Span>
          </Item>
        </Box>
      </td>

      <td style={{ width: "8%" }} />
    </Box>
  );
};

const LoanOptions = () => {
  return (
    <Box width="100%" style={{ width: "100% !important" }}>
      <Item style={loanOptionsTitle}>Loan Options Available</Item>
      <Item style={{ height: 30 }} />
      <Item>
        <Box width="100%" style={{ width: "100% !important" }}>
          <tr>
            {`{{#each loanOptions}}`}
            <td style={loanOptionsItemStyle}>
              <LoanOption image="{{image}}" loanValue="{{loanRate}}" />
            </td>
            {`{{/each}}`}
          </tr>
        </Box>
      </Item>
    </Box>
  );
};

LoanOptions.propTypes = {};

export default LoanOptions;
