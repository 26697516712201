import React from "react";
import { A, Box, Image, Item, Span } from "react-html-email";

const loanOptionsImage = {
  display: "inline",
  marginRight: 10,
  verticalAlign: "top",
};

const propertyDetailParams = {
  image: "{{image}}",
  address: "{{address}}",
  soldDate: "{{saleDate}}",
  value: "{{salePrice}}",
  propertyDetails: {
    bedroom: "{{bedrooms}}",
    bathroom: "{{bathrooms}}",
    carpark: "{{carparks}}",
    area: "{{landarea}}",
  },
};

const linkStyle = {
  cursor: "default",
  textDecoration: "none",
  color: "black",
  whiteSpace: "pre-wrap",
  fontSize: 18,
};

const PropertyItemDetail = ({
  image,
  value,
  isLandArea = false,
  isImageRight = false,
}) => {
  return (
    <Box width="100%">
      <tr>
        <td align={isImageRight ? "right" : "left"}>
          <Image style={loanOptionsImage} src={image} width={25} height={25} />
          <Span
            style={{
              fontWeight: "bold",
              fontSize: 16,
              display: "inline",
              lineHeight: "25px",
              verticalAlign: "top",
            }}
          >
            {value}
            {isLandArea && (
              <>
                <Span
                  style={{
                    fontSize: 10,
                    lineHeight: "25px",
                    verticalAlign: "top",
                  }}
                >
                  M
                </Span>
                <sup
                  style={{
                    fontSize: 10,
                    lineHeight: "15px",
                    verticalAlign: "top",
                  }}
                >
                  2
                </sup>
              </>
            )}
          </Span>
        </td>
      </tr>
    </Box>
  );
};

const PropertyDetail = ({
  image,
  address,
  soldDate,
  value,
  propertyDetails,
  isImageRight = false,
}) => {
  return (
    <Box width="100%" style={{ width: "100% !important" }}>
      <tr>
        {!isImageRight && (
          <>
            <td valign="top" style={{ width: "47%" }}>
              <Image src={image} width="100%" style={{ borderRadius: 4 }} />
            </td>
            <td style={{ width: "6%" }}>&nbsp;</td>
          </>
        )}

        <td valign="top" style={{ width: "45%" }}>
          <Box width="100%">
            <Item
              align={isImageRight ? "right" : "left"}
              style={{
                fontSize: 16,
              }}
            >
              <A href={null} style={linkStyle}>
                {address}
              </A>
            </Item>
            <Item style={{ height: 30 }} />
            <Item>
              <Box width="100%" style={{ width: "100% !important" }}>
                <tr>
                  <td>
                    <PropertyItemDetail
                      image="https://d1rndq5v7rs3ry.cloudfront.net/bedroom.png"
                      value={propertyDetails.bedroom}
                      isImageRight={isImageRight}
                    />
                  </td>
                  <td>
                    <PropertyItemDetail
                      image="https://d1rndq5v7rs3ry.cloudfront.net/bathroom.png"
                      value={propertyDetails.bathroom}
                      isImageRight={isImageRight}
                    />
                  </td>
                  <td>
                    <PropertyItemDetail
                      image="https://d1rndq5v7rs3ry.cloudfront.net/carpark.png"
                      value={propertyDetails.carpark}
                      isImageRight={isImageRight}
                    />
                  </td>
                  <td>
                    <PropertyItemDetail
                      image="https://d1rndq5v7rs3ry.cloudfront.net/landsize.png"
                      value={propertyDetails.area}
                      isImageRight={isImageRight}
                      isLandArea
                    />
                  </td>
                </tr>
              </Box>
            </Item>
            <Item style={{ height: 30 }} />
            <Item
              align={isImageRight ? "right" : "left"}
              style={{ fontSize: 14, color: "#78899F" }}
            >
              {soldDate} Sold For:
            </Item>
            <Item style={{ height: 20 }} />
            <Item
              align={isImageRight ? "right" : "left"}
              style={{ fontWeight: "bold", fontSize: 22 }}
            >
              {value}
            </Item>
          </Box>
        </td>

        {isImageRight && (
          <>
            <td style={{ width: "6%" }}>&nbsp;</td>
            <td valign="top" style={{ width: "47%" }}>
              <Image src={image} width="100%" style={{ borderRadius: 4 }} />
            </td>
          </>
        )}
      </tr>
    </Box>
  );
};

const PropertySales = () => {
  return (
    <Box width="100%">
      <Item style={{ fontWeight: "bold", textAlign: "center", fontSize: 18 }}>
        Recent Sales In Your Area
      </Item>
      <Item style={{ height: 60 }} />
      <Item>
        <Box width="100%" style={{ borderCollapse: "separate" }}>
          {`{{#if propertyGroup.groupOne}}`}
          {`{{#each propertyGroup.groupOne}}`}
          <Item>
            {`{{#if @last}}`}
            <PropertyDetail {...propertyDetailParams} isImageRight />
            {`{{else}}`}
            <PropertyDetail {...propertyDetailParams} />
            {`{{/if}}`}
          </Item>
          <Item style={{ height: 60 }} />
          {`{{/each}}`}
          {`{{/if}}`}
          {`{{#if propertyGroup.groupTwo}}`}
          {`{{#each propertyGroup.groupTwo}}`}
          <Item>
            {`{{#if @last}}`}
            <PropertyDetail {...propertyDetailParams} isImageRight />
            {`{{else}}`}
            <PropertyDetail {...propertyDetailParams} />
            {`{{/if}}`}
          </Item>
          <Item style={{ height: 60 }} />
          {`{{/each}}`}
          {`{{/if}}`}
        </Box>
      </Item>
    </Box>
  );
};

export default PropertySales;
