import React from "react";
import { A, Item } from "react-html-email";
import Box from "react-html-email/lib/components/Box";
import { primaryColor } from "../../scss/colors.scss";
import { getCurrentHostname } from "../../utils";

const linkStyle = {
  borderRadius: 4,
  border: `1px solid ${primaryColor}`,
  color: `${primaryColor}`,
  display: "block",
  paddingBottom: "6px",
  paddingTop: "6px",
  textDecoration: "none",
  width: "100%",
  textAlign: "center",
};

const LoginButton = () => {
  return (
    <Box width="100%" style={{ width: "100% !important" }}>
      <tr>
        <td style={{ width: "35%" }} />
        <td>
          <A href={`${getCurrentHostname()}/login`} style={linkStyle}>
            Login
          </A>
        </td>

        <td style={{ width: "35%" }} />
      </tr>
    </Box>
  );
};

const SharingReferral = () => {
  return (
    <Box width="100%" align="center" style={{ textAlign: "center" }}>
      <Item style={{ color: "#131C24", fontSize: 28, fontWeight: "bolder" }}>
        Refer {`{{brokerName}}`} To Your Friends
      </Item>
      <Item style={{ height: 40 }} />
      <Item style={{ fontSize: 18, fontWeight: "bolder" }}> Copy link</Item>
      <Item style={{ height: 10 }} />
      <Item style={{ fontSize: 14, fontWeight: "bolder" }}>
        <A href="https://{{brokerPath}}">{`{{brokerPath}}`}</A>
      </Item>
      <Item style={{ height: 60 }} />
      <Item>
        <LoginButton />
      </Item>
    </Box>
  );
};

export default SharingReferral;
