import React from "react";
import { Box } from "react-html-email";
import OtherExpense from "./OtherExpenseChart";
import Top5ExpenseChart from "./Top5ExpenseChart";

const ExpensesCategoriesChart = ({ top5ExpenseChart, otherExpenseChart }) => {
  return (
    <Box width="100%" style={{ width: "100% !important" }}>
      <Top5ExpenseChart top5ExpenseChart={top5ExpenseChart} />
      <OtherExpense otherExpenseChart={otherExpenseChart} />
    </Box>
  );
};

export default ExpensesCategoriesChart;
