import React from "react";
import { A, Box } from "react-html-email";
import { primaryColor } from "../../scss/colors.scss";

const linkStyle = {
  backgroundColor: primaryColor,
  borderRadius: 4,
  color: "white",
  display: "block",
  paddingBottom: "13px",
  paddingTop: "13px",
  textDecoration: "none",
  width: "100%",
  textAlign: "center",
};

const TalkToBroker = () => {
  return (
    <Box width="100%" style={{ width: "100% !important" }}>
      <tr>
        <td style={{ width: "30%" }}>&nbsp;</td>
        <td>
          <A href="https://calendly.com/minh-experity/15min" style={linkStyle}>
            Talk to your broker
          </A>
        </td>

        <td style={{ width: "30%" }}>&nbsp;</td>
      </tr>
    </Box>
  );
};

export default TalkToBroker;
